import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "@themesberg/react-bootstrap";
import CommonCard from "../../components/CommonCard";
import Navbar from "../../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/spinner";
import {
  getSeekerListing,
  newArrival,
  topRated,
} from "../../Redux/Dashboard/actions";
import NoRecordFound from "../../components/NoRecordFound";
import { markAsFavouriteJob } from "../../Redux/addJob/actions";
import { Link } from "react-router-dom";
import Profile from "../../assets/img/team/profile.png";
import ProfileCard from "../../components/profileCard";
// import { TextField } from "@mui/material";

const DashboardOverview = (props) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const SeekerList = useSelector(
    (state) => state?.Seeker?.getSeekerListing?.data
  );
  const auth = useSelector((state) => state.auth.Auther);
  const newArrivalData = useSelector(
    (state) => state?.Seeker?.newArrival?.data
  );
  const topRatedData = useSelector((state) => state?.Seeker?.topRated?.data);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [topRatedProvider, setTopRatedProvider] = useState();
  const [newArrivalProvider, setNewArrivalProvider] = useState();

  useEffect(() => {
    if (SeekerList !== undefined) {
      setData(SeekerList);
    }
  }, [SeekerList]);

  useEffect(() => {
    if (newArrivalData !== undefined) {
      setNewArrivalProvider(newArrivalData);
    }
  }, [newArrivalData]);
  useEffect(() => {
    if (topRatedData !== undefined) {
      setTopRatedProvider(topRatedData);
    }
  }, [topRatedData]);

  useEffect(() => {
    dispatch(
      getSeekerListing({
        page: 1,
        limit: 5,
        setLoader: setLoader,
        search: search,
      })
    );
  }, [search, dispatch]);
  useEffect(() => {
    // getLocation();
    dispatch(
      newArrival({
        page: 1,
        userId: auth?.id,
        count: 5,
        setLoader: setLoader,
        search: search,
      })
    );
    dispatch(
      topRated({
        page: 1,
        userId: auth?.id,
        count: 5,
        setLoader: setLoader,
        search: search,
      })
    );
  }, [search, dispatch, auth]);

  const handleClick = (id, value, isFavourite, title) => {
    let newArray = [];

    if (title === "topRate") {
      // Create a new copy of the array
      newArray = [...topRatedProvider];
      newArray[value] = { ...newArray[value], isFavourite: !isFavourite };
      setTopRatedProvider(newArray);
    } else if (title === "newArrivals") {
      // Create a new copy of the array
      newArray = [...newArrivalProvider];
      newArray[value] = { ...newArray[value], isFavourite: !isFavourite };
      setNewArrivalProvider(newArray);
    } else if (title === "recommended") {
      // Create a new copy of the array
      newArray = [...data];
      newArray[value] = { ...newArray[value], isFavourite: !isFavourite };
      setData(newArray);
    }

    // Dispatch the action
    dispatch(
      markAsFavouriteJob({
        id: id,
        setLoader: setLoader,
      })
    );
  };
  const renderCards = (items) => {
    return items?.map((value, index) => (
      <Col
        lg={2}
        md={4}
        sm={6}
        xs={12}
        className="pb-3"
        key={value.id || index}
      >
        {auth?.role?.name === "customer" ? (
          <ProfileCard
            img={value?.user?.profileImg || Profile}
            name={value.user?.fullName}
            type={"IT"}
            id={value.user?.id}
            star={value.user?.profile_rating}
            completed={value.user?.completeJobs || "0"}
          />
        ) : (
          <CommonCard
            img={value?.images || Profile}
            name={value.name}
            title="recommended"
            isFavourite={value.isFavourite}
            setLoader={setLoader}
            index={index}
            id={value.id}
            handleClick={handleClick}
            paymentType={value.paymentType}
            rate={value.rate}
            completed={"10"}
            star={value.rating}
          />
        )}
      </Col>
    ));
  };
  console.log(search, "search");
  return (
    <>
      <Navbar module={"Dashboard"} />
      <Row className="pt-2 pb-4">
        <Form.Group className="mt-3">
          <Form.Control
            type="text"
            placeholder="Search"
            label="Search"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        </Form.Group>
      </Row>
      {/* Recommended */}
      <Row className="pt-2 pb-4">
        <div className="d-flex justify-content-between mt-0 mb-4 headerBorder">
          <h4>Recommended for you</h4>
          <Link to="/recommended">view all</Link>
        </div>
        {loader ? (
          <Spinner />
        ) : (
          <>{data?.length > 0 ? renderCards(data) : <NoRecordFound />}</>
        )}
      </Row>
      {/* Booked Again */}

      {auth?.role?.name === "customer" && (
        <Row className="pt-2 pb-4">
          <div className="d-flex justify-content-between mt-0 mb-4 headerBorder">
            <h4>Book it again</h4>
            <Link to="/book_it_again">view all</Link>
          </div>
          {loader ? (
            <Spinner />
          ) : (
            <>{data?.length > 0 ? renderCards(data) : <NoRecordFound />}</>
          )}
        </Row>
      )}
      {/* Featured */}
      <Row className="py-2">
        <div className="d-flex justify-content-between mt-0 mb-4 headerBorder">
          <h4>
            {auth?.role?.name === "customer"
              ? "New Arrival Profile"
              : "New Arrival Jobs By Provider"}
          </h4>
          <Link to="/Newarrivalproviders">view all</Link>
        </div>
        {loader ? (
          <Spinner />
        ) : (
          <>{data?.length > 0 ? renderCards(data) : <NoRecordFound />}</>
        )}
      </Row>
      {/* Plumber */}
      <Row className="py-2">
        <div className="d-flex justify-content-between mt-0 mb-4 headerBorder">
          <h4>
            {auth?.role?.name === "customer"
              ? "Top Rated Profile"
              : "Top Rated Jobs"}
          </h4>
          <Link to="/TopRatedProviders">view all</Link>
        </div>
        {loader ? (
          <Spinner />
        ) : (
          <>{data?.length > 0 ? renderCards(data) : <NoRecordFound />}</>
        )}
      </Row>
    </>
  );
};

export default DashboardOverview;
