/* eslint-disable no-unused-vars, array-callback-return, react/no-unescaped-entities */
import {
  Button,
  Col,
  Form,
  Row,
  Carousel,
  Image,
} from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TimePicker from "react-time-picker";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import profile from "../../assets/img/upload.png";
import { format } from "date-fns";
import { addWorkerFormJob } from "../../Redux/addJob/actions";
import { getCategoryList, getSingleList } from "../../Redux/Category/actions";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const GeneralInfoForm = ({ isOffer, setCustomOffer, userId, users, zoom, currentUser }) => {
  console.log(currentUser, users, zoom, "here is chat details");
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBJWt1Yh6AufjxV8B8Y8UVz_25cYV1fvhs";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subCategoryData = useSelector(
    (state) => state?.Category?.SingleList?.sub_Categories
  );
  const CategoryData = useSelector((state) => state?.Category?.getCategoryList);

  // Consolidated state
  const [formState, setFormState] = useState({
    selectedImage: [],
    hours: "1",
    days: "1",
    jobType: "Part-time",
    jobPlace: "Business Location",
    paymentType: "hourly",
    categories: null,
    subCategories: null,
    location: [],
    categoryList: [],
    subCategoryList: [],
    rate: 0,
    longitude: undefined,
    latitude: undefined,
    startDate: new Date(),
    startTime: "12:00",
    endTime: "12:00",
    endDate: new Date(),
    postJob: false,
    activeButton: "",
    buttonDisable: false,
  });
  const [loader, setLoader] = useState(false);

  const iconStyle = {
    backgroundColor: "black",
    width: "50px",
    borderRadius: "50%",
    padding: "5px",
    color: "white",
    cursor: "pointer",
  };

  useEffect(() => {
    if (formState.categories?.value[0].id !== undefined) {
      dispatch(
        getSingleList({ id: formState.categories?.value[0].id, search: "" })
      );
    }
  }, [formState.categories, dispatch]);

  useEffect(() => {
    dispatch(getCategoryList({ search: "", setLoader: setLoader }));
  }, [dispatch]);

  useEffect(() => {
    const array =
      subCategoryData?.map((item) => ({
        value: [{ id: item?.id, title: item?.title, details: item?.details }],
        label: item?.title,
      })) || [];
    setFormState((prev) => ({ ...prev, subCategoryList: array }));
  }, [subCategoryData]);

  useEffect(() => {
    const array =
      CategoryData?.map((item) => ({
        value: [{ id: item?.id, title: item?.title, details: item?.details }],
        label: item?.title,
      })) || [];
    setFormState((prev) => ({ ...prev, categoryList: array }));
  }, [CategoryData]);

  const inputEl = useRef(null);
  const onButtonClick = () => {
    inputEl.current.click();
  };

  const CategorySchema = Yup.object().shape({
    jobName: Yup.string().trim().required("Job Name is required"),
    description: Yup.string().trim().required("description is required"),
    rate: Yup.number()
      .required("Rate is required")
      .positive("Rate must be a positive number")
      .min(1, "Rate should not be less than 1"),
  });

  const CategoryFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      jobName: "",
      description: "",
      rate: 0,
      jobType: "",
      jobPlace: "",
      paymentType: "",
      isPost: "",
      startDate: "",
      startTime: "",
      endTime: "",
      endDate: null,
      location: "",
      latitude: "",
      days: "",
      hours: "",
      jobImg: "",
      providers: "",
      remember: true,
      category: "",
      subCategory: "",
    },
    validationSchema: CategorySchema,
    onSubmit: async (values, action) => {
      if (
        !formState.startTime ||
        !formState.endTime ||
        formState.location === "" ||
        !formState.location ||
        formState.selectedImage.length === 0 ||
        !formState.subCategories
      ) {
        return;
      }
      setFormState((prev) => ({
        ...prev,
        postJob: true,
        buttonDisable: true,
      }));
      const formattedStartDate = `"${format(
        formState.startDate,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      )}"`;
      const formattedEndDate = `"${format(
        formState.endDate,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      )}"`;
      let nestedArray = formState.location ? [formState.location] : [];
      let flattenedArray = nestedArray.flat(Infinity);
      let data = {
        isOffer: isOffer,
        customerId: userId,
        setCustomOffer: setCustomOffer,
        name: values.jobName,
        description: values.description,
        rate: values.rate,
        unit: values.unit,
        jobType: formState.jobType,
        jobPlace: formState.jobPlace,
        paymentType: formState.paymentType,
        category: formState.subCategories.value[0].id,
        days: formState.days,
        hours: formState.hours,
        location: flattenedArray,
        latitude: formState.latitude,
        longitude: formState.longitude,
        // setReset: action.resetForm,
        startTime: JSON.stringify(formState.startTime),
        endTime: JSON.stringify(formState.endTime),
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        zoom:zoom,
        users:users,
        currentUser:currentUser,
        jobImg:
          formState.selectedImage.length > 0 ? formState.selectedImage : null,
        // setSelectedImage: setFormState,
        history: navigate,
        setPostJob: setFormState,
        setButtonDisabled: setFormState,
      };

      console.log(data, "payload");
      // setCustomOffer(false);
      dispatch(addWorkerFormJob(data, currentUser, users, zoom));
    },
  });

  const handleInputChange = (e) => {
    setFormState((prev) => ({ ...prev, location: e.target.value }));
  };

  const imageChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setFormState((prev) => ({ ...prev, selectedImage: filesArray }));
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setFormState((prev) => ({
        ...prev,
        location: place.formatted_address,
        longitude: place.geometry.location.lng(),
        latitude: place.geometry.location.lat(),
      }));
    },
    options: { types: ["(regions)"] },
    defaultValue: formState.location,
  });

  const PreventFirstZero = (e) => {
    if (e.target.value.length === 0 && e.which === "0".charCodeAt(0)) {
      e.preventDefault();
      return false;
    }
  };
  console.log(formState.selectedImage, "formState.selectedImage?.length");
  return (
    <>
      <Col className={"d-flex justify-content-center"}>
        <Carousel
          controls={formState.selectedImage?.length > 1}
          interval={null}
          style={{ width: "150px" }}
          indicators={false}
          prevIcon={
            <div style={iconStyle}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          }
          nextIcon={
            <div style={iconStyle}>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          }
        >
          {formState.selectedImage && formState?.selectedImage?.length > 0 ? (
            formState.selectedImage.map((item, index) => (
              <Carousel.Item key={index}>
                <Image
                  src={URL.createObjectURL(item)}
                  onClick={onButtonClick}
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                  }}
                  className="navbar-brand-light"
                />
              </Carousel.Item>
            ))
          ) : (
            <Carousel.Item>
              <img
                src={profile}
                alt="60px"
                width={"130px"}
                onClick={onButtonClick}
                style={{ cursor: "pointer", transition: "transform 0.5s ease" }}
              />
            </Carousel.Item>
          )}
        </Carousel>
        {CategoryFormik.touched.jobImg &&
          formState.selectedImage.length === 0 && (
            <div style={{ color: "red" }}>Image is Required</div>
          )}
        <Form.Control
          accept="image/*"
          type="file"
          id="file"
          name="file"
          onChange={imageChange}
          className="d-none"
          ref={inputEl}
          multiple
        />
      </Col>
      <div className="mt-3 p-1">
        <Form
          onSubmit={CategoryFormik.handleSubmit}
          encType="multipart/form-data"
        >
          <Row>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="firstName" className="mb-2">
                <Form.Label>Job Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your job name"
                  name="jobName"
                  value={CategoryFormik.values.jobName}
                  onChange={CategoryFormik.handleChange}
                  isInvalid={
                    CategoryFormik.touched.jobName &&
                    !!CategoryFormik.errors.jobName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.jobName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="lastName" className="mb-2">
                <Form.Label>Category</Form.Label>
                <Select
                  name="categories"
                  options={formState.categoryList}
                  onChange={(value) =>
                    setFormState((prev) => ({ ...prev, categories: value }))
                  }
                  defaultValue={formState.categories}
                  value={formState.categories}
                  isInvalid={
                    CategoryFormik.touched.category &&
                    !!CategoryFormik.errors.category
                  }
                />
                {CategoryFormik.touched.category &&
                  formState.categories?.label === undefined && (
                    <div style={{ color: "red" }}>Category is Required</div>
                  )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="subCategory" className="mb-2">
                <Form.Label>Sub Category</Form.Label>
                <Select
                  name="subCategories"
                  options={formState.subCategoryList}
                  onChange={(value) =>
                    setFormState((prev) => ({ ...prev, subCategories: value }))
                  }
                  defaultValue={formState.subCategories}
                  value={formState.subCategories}
                  isInvalid={
                    CategoryFormik.touched.subCategory &&
                    !!CategoryFormik.errors.subCategory
                  }
                />
                {CategoryFormik.touched.subCategory &&
                  !formState.subCategories && (
                    <div style={{ color: "red" }}>Sub Category is Required</div>
                  )}
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12} className="mb-2">
              <Form.Label>Location</Form.Label>
              <Form.Control
                value={formState.location}
                ref={ref}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {CategoryFormik.touched.location &&
                formState.location.length === 0 && (
                  <div style={{ color: "red" }}>Location is required</div>
                )}
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="description" className="mb-2">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter job description"
                  name="description"
                  value={CategoryFormik.values.description}
                  onChange={CategoryFormik.handleChange}
                  isInvalid={
                    CategoryFormik.touched.description &&
                    !!CategoryFormik.errors.description
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.description}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="rate" className="mb-2">
                <Form.Label>Rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter rate"
                  name="rate"
                  value={CategoryFormik.values.rate}
                  onChange={CategoryFormik.handleChange}
                  onKeyPress={PreventFirstZero}
                  isInvalid={
                    CategoryFormik.touched.rate && !!CategoryFormik.errors.rate
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.rate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="paymentType" className="mb-2">
                <Form.Label>Payment Type</Form.Label>
                <Select
                  name="paymentType"
                  options={[
                    { value: "hourly", label: "Hourly" },
                    { value: "fixed", label: "Fixed" },
                  ]}
                  onChange={(e) =>
                    setFormState((prev) => ({ ...prev, paymentType: e.value }))
                  }
                  defaultValue={{
                    value: formState.paymentType,
                    label: formState.paymentType,
                  }}
                  isInvalid={
                    CategoryFormik.touched.paymentType &&
                    !!CategoryFormik.errors.paymentType
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.paymentType}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="jobPlace" className="mb-2">
                <Form.Label>Job Place</Form.Label>
                <Select
                  name="jobPlace"
                  options={[
                    { value: "Business Location", label: "Business Location" },
                    { value: "Remote", label: "Remote" },
                  ]}
                  onChange={(e) =>
                    setFormState((prev) => ({ ...prev, jobPlace: e.value }))
                  }
                  defaultValue={{
                    value: formState.jobPlace,
                    label: formState.jobPlace,
                  }}
                  isInvalid={
                    CategoryFormik.touched.jobPlace &&
                    !!CategoryFormik.errors.jobPlace
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.jobPlace}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Group id="jobType" className="mb-2">
                <Form.Label>Job Type</Form.Label>
                <fieldset className="d-flex radioButton">
                  <Form.Check
                    type="radio"
                    checked={formState.jobType === "Part-time"}
                    label="Part-time"
                    name="jobType"
                    value="Part-time"
                    className="radio1"
                    onChange={(event) => {
                      setFormState((prev) => ({
                        ...prev,
                        jobType: event.target.value,
                      }));
                    }}
                  />
                  <Form.Check
                    type="radio"
                    checked={formState.jobType === "Permanent"}
                    label="Full-time"
                    name="jobType"
                    value="Permanent"
                    onChange={(event) => {
                      setFormState((prev) => ({
                        ...prev,
                        jobType: event.target.value,
                      }));
                    }}
                  />
                </fieldset>
              </Form.Group>
            </Col>
            <Col md={12} lg={12} xl={12} className="mb-2">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={format(formState.startDate, "yyyy-MM-dd")} // Format the date for input
                onChange={(e) => {
                  setFormState((prev) => ({
                    ...prev,
                    startDate: new Date(e.target.value), // Update state with new date
                  }));
                }}
                isInvalid={
                  CategoryFormik.touched.startDate && !formState.startDate
                }
              />
              {CategoryFormik.touched.startDate && !formState.startDate && (
                <div style={{ color: "red" }}>Start Date is required</div>
              )}
            </Col>
            <Col md={12} lg={12} xl={12} className="mb-2">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={format(formState.endDate, "yyyy-MM-dd")} // Format the date for input
                onChange={(e) => {
                  setFormState((prev) => ({
                    ...prev,
                    endDate: new Date(e.target.value), // Update state with new date
                  }));
                }}
                isInvalid={CategoryFormik.touched.endDate && !formState.endDate}
              />
              {CategoryFormik.touched.endDate && !formState.endDate && (
                <div style={{ color: "red" }}>End Date is required</div>
              )}
            </Col>
            <Col md={12} lg={12} xl={12} className="mb-2">
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                as={TimePicker}
                disableClock={true}
                name="startTime"
                value={formState.startTime}
                onChange={(newValue) => {
                  setFormState((prev) => ({ ...prev, startTime: newValue }));
                }}
                isInvalid={CategoryFormik.touched.startTime && !formState.startTime}
              />
              {CategoryFormik.touched.startTime && !formState.startTime && (
                <Form.Control.Feedback type="invalid">Start Time is required</Form.Control.Feedback>
              )}
            </Col>
            <Col md={12} lg={12} xl={12} className="mb-2">
              <Form.Label>End Time</Form.Label>
              <Form.Control
                as={TimePicker}
                disableClock={true}
                name="endTime"
                value={formState.endTime}
                onChange={(newValue) => {
                  setFormState((prev) => ({ ...prev, endTime: newValue }));
                }}
                isInvalid={CategoryFormik.touched.endTime && !formState.endTime}
              />
              {CategoryFormik.touched.endTime && !formState.endTime && (
                <Form.Control.Feedback type="invalid">End Time is required</Form.Control.Feedback>
              )}
            </Col>
          </Row>
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              variant="primary"
              type="submit"
              disabled={formState.buttonDisable}
              onClick={() =>
                setFormState((prev) => ({
                  ...prev,
                  activeButton: "Post",
                }))
              }
            >
              {formState.buttonDisable ? "Loading..." : "Post"}
            </Button>
          </Box>
        </Form>
      </div>
    </>
  );
};
